import {useParams} from "react-router-dom";
import {ComplementDetail} from "./detail/ComplementDetail";
import {ComplementsContainer} from "./complementscontainer/ComplementsContainer";

export const Complements = () => {
	let params = useParams()
	function Title(title, id) {
		if (!id) {
			return (
				<div>
					<h3>{title}</h3>
				</div>
			)
		}
	}

	return (
		<main className="complements__container">
			{
				Title('Complementos', params.id)
			}
			<section>
				{
					params.id ? <ComplementDetail id={params.id} />
						: <ComplementsContainer />
				}
			</section>
		</main>
	)
}
