import {Link} from "react-router-dom";

export const ComplementCard = ({complement}) => {
    const {id, name, category, picture} = complement
    const placeholder = '/assets/complements/noimage.svg'
    return (
        <div className="complement__card">
            <img src={picture ? '/' + picture : placeholder} alt={name} />
            <small>{category}</small>
            <h3>{name}</h3>
            <Link to={'/complementos/' + id} className={'button'}>Ver más</Link>
        </div>
    )
}
