import {Warranty} from "../helpers/warranty/Warranty"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export const Concrete = () => {
    const settings = {
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        infinite: true,
        fade: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
    }
    return (
        <main className="concrete__container">
            <Warranty duration={20} />
            <section>
                <h2>PISCINA DE HORMIGÓN PROYECTADO H30</h2>
                <p>Estructura de la piscina: piso, paredes, vereda y solarium totalmente en HORMIGÓN GUNITADO H30 (sistema que proyecta hormigón con bomba de alta presión). Se procede al armado de una viga/columna longitudinal y vigas/columnas transversales de 0.20 x 0.20 cm., con hierro 8 mm y estribos de 6mm. Se armará un mallado en piso y paredes, ambas con hierros 8 mm y a una distancia entre sí de 20 cm., quedando toda una sola estructura. Luego se hormigona un espesor de 15 cm. en piso y paredes.</p>
                <p>Luego se procede a revocar con material Hidrofugo las paredes, piso y escalera. El espesor del revoque es de 2 cm. (filtrado y fratachado). Esto permite una correcta IMPERMEABILIZACIÓN de toda la piscina. Se deja preparado par luego dar la pintura final.</p>
                <p>En toda la superficie perimetral de la piscina, se colocara sobre base de HORMIGÓN ARMADO una vereda de 0.50 cm. con Borde Plano ATERMICO Y ANTIDESLIZANTE.</p>
                <Slider {...settings}>
                    <img src="/assets/concrete/concrete_00.webp" alt="concrete" />
                    <img src="/assets/concrete/concrete_01.webp" alt="concrete" />
                    <img src="/assets/concrete/concrete_02.webp" alt="concrete" />
                    <img src="/assets/concrete/concrete_03.webp" alt="concrete" />
                    <img src="/assets/concrete/concrete_04.webp" alt="concrete" />
                    <img src="/assets/concrete/concrete_05.webp" alt="concrete" />
                    <img src="/assets/concrete/concrete_06.webp" alt="concrete" />
                </Slider>
            </section>
        </main>
    )
}
