import {PoolCard} from "./poolcard/PoolCard"
import {getPools} from "../../../../db/dbConnector"
import {useEffect, useState} from "react";

export const PoolsContainer = () => {
    const [pools, setPools] = useState([])
    useEffect(()=>{
        getPools()
            .then(items => {
                setPools(items)
            })
    })
    return (
        <div className="pools__container">
            { pools.map(pool =>
                <PoolCard
                    key={pool.id}
                    pool={pool}
                />
            )}
        </div>
    )
}