import { useState, useEffect } from "react";
import { getComplements } from "../../../../../db/dbConnector";
import { ComplementCard } from "../../../complements/complementscontainer/complementcard/ComplementCard";

export const ComplementsContainer = () => {
	const [complements, setComplements] = useState([])
	useEffect(()=>{
		getComplements()
			.then(items => {
				setComplements(items)
			})
	})
	return (
		<div className="complements__sub__container">
			{complements.map(complement =>
				<ComplementCard
					key={complement.id}
					complement={complement}
				/>
			)}
		</div>
	)
}
