import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from "./components/views/home/Home";
import {Fiber} from "./components/views/fiber/Fiber";
import {Concrete} from "./components/views/concrete/Concrete";
import {Complements} from "./components/views/complements/Complements";
import {Contact} from "./components/views/contact/Contact";
import {NotFound} from "./components/views/notfound/NotFound";
import {App} from "./App";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import './styles/styles'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        language="es-AR"
        useRecaptchaNet={false}
        scriptProps={
            {
                async: false,
                defer: false,
                appendTo: "head",
                nonce: undefined
            }
        }
    >
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />} errorElement={<NotFound />} >
                    <Route index element={<Home />} />
                    <Route path="/fibra" element={<Fiber />} >
                        <Route path=":id" element={<Fiber />} />
                    </Route>
                    {/* <Route path="/hormigon" element={<Concrete />} /> */}
                    <Route path="/complementos" element={<Complements />} >
                        <Route path=":id" element={<Complements />} />
                    </Route>
                    <Route path="/contacto" element={<Contact />} />
                </Route>
                <Route path="*" redirect="/" />
            </Routes>
        </BrowserRouter>
    </GoogleReCaptchaProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
