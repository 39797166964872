import {OverlappingMenu} from "../helpers/overlappingMenu/OverlappingMenu";
import {Branding} from "../helpers/branding/Branding";
import {About} from "../helpers/about/About";
import {Features} from "../helpers/features/Features";
import {Banner} from "../helpers/banner/Banner";
/*import {Map} from "../footer/section/map/Map";*/

export const Home = () => {
    return (
            <main>
                <OverlappingMenu />
                <Branding />
                <About />
                <Features />
                <Banner />
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.908552057534!2d-58.72223742359023!3d-34.60647387295331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcbe122e5435c9%3A0x335e56ed55bdc696!2sDent%20Piletas!5e0!3m2!1ses-419!2sar!4v1710792016457!5m2!1ses-419!2sar" width="100%" height="480" title="Dent Piletas"></iframe>
                {/*<Map />*/}
            </main>
    )
}