import {NavLink} from "react-router-dom";

export const Menu = () => {
    const mobileToggle = (e) => {
        const mobileMenu = document.getElementById("mobileMenu")
        setTimeout(async () => {
            mobileMenu.click();
        }, 500)
    }

    return (
        <div className="navbar__nav" id="main__nav">
            <ul>
                <li><NavLink to="/" onClick={mobileToggle}>Inicio</NavLink><hr /></li>
                <li><NavLink to="/fibra" onClick={mobileToggle}>Piletas de fibra</NavLink><hr /></li>
                {/* <li><NavLink to="/hormigon" onClick={mobileToggle}>Piletas de hormigón</NavLink><hr /></li> */}
                <li><NavLink to="/complementos" onClick={mobileToggle}>Complementos</NavLink><hr /></li>
                <li><NavLink to="/contacto" onClick={mobileToggle}>Contacto</NavLink><hr /></li>
            </ul>
        </div>
    )
}
