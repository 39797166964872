import {Warranty} from "../helpers/warranty/Warranty";
import {PoolsContainer} from "./poolscontainer/PoolsContainer";
import {useParams} from "react-router-dom";
import {FiberDetail} from "./detail/FiberDetail";
import {Carousel} from "react-responsive-3d-carousel";

function Title(title, id) {
    if (!id) {
        return (
            <div>
                <h3>{title}</h3>
            </div>
        )
    }
}

export const Fiber = () => {
    let params = useParams()

    return (
        <main className="fiber__container">
            <Warranty duration={10} />
                {
                    Title('Piscinas de fibra', params.id)
                }
            <section>
                {
                    params.id ? <FiberDetail id={params.id} />
                        : <PoolsContainer />
                }
            </section>
            <section>
                {
                    Title('Nuestros trabajos', params.id)
                }
                <Carousel spread={"narrow"} showStatus={false} interval={4000} depth={6} showIndicators={false}>
                    <img src="/assets/portfolio/0001.webp" alt="slide_01"/>
                    <img src="/assets/portfolio/0002.webp" alt="slide_02"/>
                    <img src="/assets/portfolio/0003.webp" alt="slide_03"/>
                    <img src="/assets/portfolio/0004.webp" alt="slide_04"/>
                    <img src="/assets/portfolio/0005.webp" alt="slide_05"/>
                </Carousel>
            </section>
        </main>
    )
}
