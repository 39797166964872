import axios from "axios";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";

export const ContactForm = () => {
    const { register, trigger, handleSubmit, reset, formState: { errors } } = useForm();
    const sendMail = async (data) => {
        try {
            const queryParams = new URLSearchParams(data).toString();
            const response = await axios.get(`https://dentpiletas.com.ar/static/php/mailer.php?${queryParams}`, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            Swal.fire({
                title: "Mensaje enviado",
                text: response.data.message,
                icon: "success",
                confirmButtonText: "Aceptar"
            });
            reset();
        } catch (error) {
            // Handle error
            Swal.fire({
                title: "Error",
                text: "Error al enviar el mensaje",
                icon: "error",
                confirmButtonText: "Aceptar"
            });
        }
    }

    return (
        <div className="contact__form">
            <h2>Dejanos un mensaje</h2>
            <p>Podes contactarnos de forma telefónica o a través de nuestra página en Facebook.</p>
            <form onSubmit={handleSubmit((data) => sendMail(data))}>
                <div>
                    <label htmlFor="fullname">Nombre y apellido
                        <input id="fullname" name="fullname" type="text"
                               {...register("fullname",
                                   {
                                       required: true,
                                       minLength: 4,
                                       maxLength: 32,
                                       pattern: /^[A-Za-zÁáÉéÍíÓóÚúÜüÑñ\s]+$/,
                                       onChange: () => trigger("fullname")
                                   }
                               )}
                        />
                        {errors.fullname && <small className='text_error'>*Nombre y apellido es requerido</small>}
                    </label>
                    <label htmlFor="phone">Teléfono
                        <input id="phone" name="phone" type="text"
                               {...register("phone",
                                   {
                                       required: false,
                                       pattern: /^\+?([0-9]{1,3})?[-. ]?([0-9]{1,4})[-. ]?([0-9]{1,4})[-. ]?([0-9]{1,9})$/,
                                       onChange: () => trigger("phone")
                                   }
                               )}
                        />
                        {errors.phone && <small className='text_error'>*Teléfono invalido</small>}
                    </label>
                </div>
                <label htmlFor="email">Email
                    <input id="email" name="email" type="text"
                           {...register("email",
                               {
                                   required: true,
                                   pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                   onChange: () => trigger("email")
                               }
                           )}
                    />
                    {errors.email && <small className='text_error'>*Por favor proporcione en email válido</small>}
                </label>
                <label htmlFor="city">Localidad
                    <input id="city" name="city" type="text"
                           {...register("city",
                               {
                                   required: true,
                                   minLength: 2,
                                   maxLength: 32,
                                   pattern: /^[A-Za-zÁáÉéÍíÓóÚúÜüÑñ\s]+$/,
                                   onChange: () => trigger("city")
                               }
                           )}
                    />
                    {errors.city && <small className='text_error'>*Ciudad es requerida</small>}
                </label>
                <label htmlFor="message">Mensaje
                    <textarea id="message" name="message" rows="5"
                              {...register("message",
                                  {
                                      required: true,
                                      minLength: 10,
                                      maxLength: 256,
                                      onChange: () => trigger("message")
                                  }
                              )}
                    />
                    {errors.message && <small className='text_error'>*Por favor proporcione un mensaje de al menos 10
                        caracteres</small>}
                </label>
                <input type="hidden" id="recaptchaToken" name="recaptchaToken"/>

                <button type="submit">Enviar mensaje</button>
            </form>
        </div>
    )
}
