import logo from "./logo.svg";
import {NavLink} from "react-router-dom";
export const Logo = () => {
    return (
        <div className="navbar__brand">
            <NavLink to="/">
                <img src={logo} alt="Dent Piletas" className="brand__logo" />
            </NavLink>
        </div>
    )
}
