import {useEffect, useState} from "react";
import {getPools} from "../../../../db/dbConnector";
import {Icon} from '@iconify/react';
import {ComplementsContainer} from "./complements/ComplementsContainer";

export const FiberDetail = ({id}) => {
    const [pools, setPools] = useState([])
    const [pool, setPool] = useState({sizes: []})
    const placeholder = '/assets/complements/noimage.svg'
    const goToTopElement = () => {
        document.getElementById('top').scrollIntoView({ behavior: 'smooth' })
    };

    useEffect(()=>{
        getPools().then(pools => {
            setPools(pools)
            setPool(pools.find(item => item.id === id))
            goToTopElement()
        })
    },[pools,id])
    return (
        <div className="pool__detail" id="top">
            <div className="detail__title">
                <h3>{pool.category}</h3><small>/ {pool.name}</small>
                <button className="btn btn__back" onClick={() => window.history.back()}>Volver...</button>
            </div>
            <div className="detail__container">
                <div className="detail__gallery">
                    <img src={pool.picture ? '/' + pool.picture : placeholder} alt={pool.name}/>
                </div>
                <div className="detail__content">
                <h3>{pool.name}</h3>
                    <div className="detail__sizes">
                        <p>Medidas disponibles</p>
                        <p>{pool.sizes.map(size => size + " | ")}</p>
                    </div>
                    <p>{pool.description}</p>
                    <a href={"https://wa.me/5491160140192?text=Hola%20quiero%20presupuesto%20de%20" + pool.name} target={"_blank"} rel={'noreferrer'} className="btn btn-budget"><Icon
                        icon={'bi:whatsapp'}/>Solicitar presupuesto</a>
                </div>
            </div>
            <section className="detail__complements">
                <h3>Complementos</h3>
                <ComplementsContainer />
            </section>
        </div>
    )
}
