import {useEffect, useState} from "react";
import {getComplements} from "../../../../db/dbConnector";
import {Icon} from '@iconify/react';

export const ComplementDetail = ({id}) => {
	const [complements, setComplements] = useState([])
	const [complement, setComplement] = useState({})
	const goToTopElement = () => {
		document.getElementById('top').scrollIntoView({ behavior: 'smooth' })
	};

	useEffect(()=>{
		getComplements().then(complements => {
			setComplements(complements)
			setComplement(complements.find(item => item.id === id))
			goToTopElement()
		})
	},[complements,id])
	return (
		<div className="complement__detail" id="top">
			<div className="detail__title">
				<h3>{complement.category}</h3><small>/ {complement.name}</small><button className="btn btn__back" onClick={()=>window.history.back()}>Volver...</button>
			</div>
			<div className="detail__container">
				<div className="detail__gallery">
					<img src={"../" + complement.picture} alt={complement.name}/>
				</div>
				<div className="detail__content">
					<h3>{complement.name}</h3>
					<p>{complement.description}</p>
					<a href={"https://wa.me/5491160140192?text=Hola%20quiero%20presupuesto%20de%20" + complement.name} target={"_blank"} rel={'noreferrer'} className="btn btn-budget"><Icon icon={'bi:whatsapp'} />Solicitar presupuesto</a>
				</div>
			</div>
		</div>
	)
}
